import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'formly-masked-input',
  styles: `
    input {
      border: none;
      width: 100%;
      outline: none;
      background: white;
    }
  `,
  template: `
    <ion-item lines="none">
      <ion-label>{{ props.label }}</ion-label>
    </ion-item>
    <ion-item>
      <input [formControl]="formControl"
             [formlyAttributes]="field"
             [mask]="to.maskString"
             [prefix]="to.prefixString || ''"
             [type]="field.type === 'phone' ? 'tel' : 'text'">
    </ion-item>
 `,
 providers: [provideNgxMask(),NgxMaskDirective]
})
export class FormlyMaskedInputComponent extends FieldType {
}
